
import i18next from 'i18next';

i18next.init({
  fallbackLng: 'en',
  resources: {
      es: {
          translations: require('./locales/es/translations.json')
      },
      en: {
          translations: require('./locales/en/translations.json'),
          terms: require('./locales/en/terms.json')
      },
      de: {
          translations: require('./locales/de/translations.json')
      },
      fr: {
          translations: require('./locales/fr/translations.json')
      }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
      escapeValue: false, // not needed for react!!
  }
});



i18next.languages = ['es', 'en', 'de', 'fr'];

export default i18next;