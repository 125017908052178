import React, {useState} from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby";
import logo from "./assets/logo-black-3.svg";
import searchIcon from "./assets/search.svg";
import BurgerMenu from "./heroSection/heroMenu/burgerMenu";

const CTAHeader = () => {
  const { t } = useTranslation(['translations','sale']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVisible , setIsVisible ] = useState(false)
  useScrollPosition(({ currPos }) => { setIsVisible(currPos.y < 0)}, [isVisible])
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)
 
  return (
    <Wrapper $isVisible={isVisible}>
      <LogoLink aria-label="Homepage" to={t('urls.root')}>
        <Logo $widthFactor={1} src={logo} alt="audiodesires logo"/> 
      </LogoLink>
      <ButtonsWrapper>
        <SearchIcon to={t('urls.search')}>
          <img src={searchIcon} alt="search"/>
        </SearchIcon>
        <BurgerMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
        <RegisterLink  to={t('urls.register')}>{t('hero-menu.cta')}</RegisterLink>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default CTAHeader



const Wrapper = styled.div`
  box-sizing:border-box;
  width:100%;
  background-color:white;
  box-shadow: -12px 4px 61px -16px rgba(0,0,0,0.41);
  position:fixed;
  top:0;
  left:0;
  z-index:100;
  padding:16px;
  display: ${props => props.$isVisible ? "flex" : "none"};
   align-items: center;
  
`;


const SearchIcon = styled(Link)`
  text-decoration:none;
  color:white;
  background-color:${props => props.theme.secondaryColor};
  height:40px;
  width:40px;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:25px;
  @media ${props => props.theme.phonePort} {
    padding: 5px 10px;
    font-size:0.8rem;
  }
`


const LogoLink = styled(Link)`
  flex-grow:1;
  height: 100%;
  display: flex;
`

const ButtonsWrapper = styled.div`
  font-family:${props => props.theme.mainFontParagraph};
  text-transform:uppercase;
  display:flex;
  gap:10px;
`

const Logo = styled.img`
  width: 240px;
  @media ${props => props.theme.phoneLand} {
    margin:0px 0px;
    width: 130px;
    height:auto;
  }
  @media ${props => props.theme.phonePort} {
    margin:0px 0px;
    width:130px;
    height:auto;
  }
  @media (max-width: 380px) {
    margin:0px 0px;
    width:110px;
    height:auto;
  }
`
const RegisterLink= styled(Link)`
  text-decoration:none;
  color:white;
  background-color:${props => props.theme.secondaryColor};
  padding:10px 50px;
  border-radius:25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: fit-content;
  @media ${props => props.theme.phonePort} {
    padding: 5px 10px;
    font-size:0.8rem;
  }
`