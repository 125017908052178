import React, {Fragment} from "react"
import styled from 'styled-components'
import { usePageContext } from "./hooks/usePageContext"
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next'
import LogoPic from "./assets/logo_white-400px.png"

const Footer = () => {
  const { t } = useTranslation()
  const { lang, languages, paths } = usePageContext();
  const columns = t('footer.columns', { returnObjects: true });
  const FullLangNames = {en: 'English',es: 'Español', de: 'Deutsch', fr: 'Français'}
  const availableLanguages = languages.filter(language => paths[language]).filter(language => !paths[language]?.includes("undefined"))
  return (
    <FooterWrapper>
      <FooterColumnBigLogo>
        <LogoLink aria-label="Homepage" to={"/"}>
        <LogoWrapper>
          <Logo src={LogoPic} alt={"Audiodesires Logo"} loading="lazy"/>
          </LogoWrapper>
        </LogoLink>
        <FooterLink to={t('urls.login')}>{t('hero-menu.login')}</FooterLink>
        {languages.length > 1 &&
          <>
            <FooterTitle>{t('footer.langSwitch')}</FooterTitle>
            {availableLanguages.map((language,index) => (
              <FooterLink key={index} to={paths[language]}>{FullLangNames[language]}</FooterLink>
            ))}
          </>
        }
      </FooterColumnBigLogo>
      {columns.map((column, index) => (
        <FooterColumn key={`footercolumn_${index}`}>
          <FooterTitle>{column.title}</FooterTitle>
          {column.links.filter(link => lang === link.showOnlyInLang || !link.showOnlyInLang).map((link, linkIndex) => (
            <Fragment key={`footerlink_${linkIndex}`}>
              {link.type === "internal" ? 
                <FooterLink to={link.to}  target={link.target}>{link.text}</FooterLink>
               : 
                <FooterExternalLink  href={link.href} target="_blank">{link.text}</FooterExternalLink>
              }
            </Fragment>
          ))}
        </FooterColumn>
      ))}
    </FooterWrapper>
  );
}


export default Footer


const FooterWrapper = styled.footer`
  position:relative;
  bottom: 0px;
  width: 100%;
  padding: 70px 50px;
  justify-content: space-between;
  align-items:end;
  flex-direction:row;
  flex-wrap: wrap;
  display:flex;
  background-color:${props => props.theme.darkGrey};
`

const FooterColumn = styled.nav`
  margin:20px 0px;
  ${props => props.hidden ?  'display:none' : 'display:flex;'};
  color:white;
  width: 300px;
  flex-direction: column;
  @media ${props => props.theme.desktopUp && !props.boxLayout} {
    margin:0px 0px;
    width: 200px;
  }
`

const FooterColumnBig = styled.nav`
  flex-wrap: wrap;
  color:white;
  width: 300px;
  display:flex;
  flex-direction: row;
  margin:20px 0px;
  @media ${props => props.theme.desktopUp && !props.boxLayout} {
    margin:0px 0px;
  }
`

const FooterColumnBigLogo = styled(FooterColumnBig)`
  flex-direction: column;
`

const Logo = styled.img`
  width:200px;
  height:66px;
`


const LogoWrapper = styled.div`
width:200px;
`


const LogoLink = styled(Link)`
 
`

const FooterTitle = styled.h4`
  font-family:${props => props.theme.mainFontParagraph};
  text-transform:uppercase;
  font-weight:700;
  font-size: 14px;
  color:#bbbbbb;
  margin: 10px 0px;
`


const LinkStyles = `
  font-weight:700;
  font-size: 12px;
  color: white;
  height: 48px;
  text-decoration: none;
  display: flex;
  align-content: center;
  align-items: center;
  margin-top: -12px;
  &:hover{  
    text-decoration: underline;
  }
`

const FooterLink = styled(Link)`
  ${LinkStyles}
  font-family:${props => props.theme.mainFontParagraph};
`


const FooterExternalLink = styled.a`
  ${LinkStyles}
  font-family:${props => props.theme.mainFontParagraph};
`