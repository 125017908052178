import React from 'react'
import { createGlobalStyle } from "styled-components"
import LightTheme from '../themes/LightTheme'
import {ThemeProvider} from 'styled-components'
import {UTMContextProvider} from '../components/context/ContextUTM'
import Footer from './global/Footer';
import { PublicAudioContextProvider } from './context/AudioPlayerContext'
import ScrollMenu from './global/ScrollMenu'
const GlobalStyle = createGlobalStyle`
  * {  
    box-sizing: border-box;
  }
  html {
    background-color:#ededed;
    box-sizing: border-box;
  }
  body {
    min-height: 100vh;
    position:relative;
    width:100%;
    margin:0px;
    color:#272727;
    background-color:white;
    overflow-x: hidden;
  }
  a{
    color:#272727;
  }
  h1,h2,h3,h4,h5 {
    font-family: 'Romana Becker Demi','Book Antiqua Bold', serif; 
    font-weight: normal; 
    font-style: normal;
  }
  p{
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    line-height: 1.6
  }
  li{
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
  }
`


const Layout = ({ children, location }) => {



  return (
    <>
    <PublicAudioContextProvider>
      <ThemeProvider theme={LightTheme}>
        <ScrollMenu/>
        <UTMContextProvider location={location}>
          <GlobalStyle/>
          <div>{children}</div>
          <Footer/>
        </UTMContextProvider>
      </ThemeProvider>
    </PublicAudioContextProvider>
    </>
      
  )
}

export default Layout