import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const columns = t('footer.columns', { returnObjects: true });

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  return (
    <MenuContainer>
      <BurgerButton onClick={toggleMenu} aria-label="Open Menu">
        <BurgerIcon $isOpen={isOpen}>
          <span></span>
          <span></span>
          <span></span>
        </BurgerIcon>
      </BurgerButton>

      <MenuOverlay $isOpen={isOpen}>
        <CloseButton onClick={toggleMenu}>✕</CloseButton>
        <MenuContent>
          <MenuList>
            <MenuColumn>
              <MenuLink to={t('urls.login')}>{t('login.title')}</MenuLink>
            </MenuColumn>
            {columns.map((column, index) => (
              column.title !== 'Company' && (
                <MenuColumn key={`burgercolumn_${index}`}>
                  <MenuTitle>{column.title}</MenuTitle>
                  {column.links.filter(link => lang === link.showOnlyInLang || !link.showOnlyInLang).map((link, linkIndex) => (
                    <MenuItem key={`burgerlink_${linkIndex}`}>
                      {link.type === "internal" ? 
                        <MenuLink to={link.to} target={link.target}>{link.text}</MenuLink>
                      : 
                        <MenuExternalLink href={link.href} target="_blank">{link.text}</MenuExternalLink>
                      }
                    </MenuItem>
                  ))}
                </MenuColumn>
              )
            ))}
          </MenuList>
        </MenuContent>
      </MenuOverlay>
    </MenuContainer>
  )
}

export default BurgerMenu

const MenuContainer = styled.div`
  position: relative;
`

const BurgerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  background-color: ${props => props.theme.secondaryColor};
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  @media ${props => props.theme.phonePort} {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
`

const BurgerIcon = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  span {
    display: block;
    width: 100%;
    height: 1.5px;
    background: white;
    border-radius: 2px;
    transition: all 0.3s ease;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ $isOpen }) => $isOpen ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${({ $isOpen }) => $isOpen ? '0' : '1'};
    }

    &:last-child {
      transform: ${({ $isOpen }) => $isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
`

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  visibility: ${({ $isOpen }) => $isOpen ? 'visible' : 'hidden'};
  opacity: ${({ $isOpen }) => $isOpen ? '1' : '0'};
  transition: all 0.3s ease;
  z-index: 1000;
`

const MenuContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MenuList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MenuColumn = styled.div`
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  
`

const MenuTitle = styled.h4`
  font-family: ${props => props.theme.mainFontParagraph};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #bbbbbb;
  margin: 10px 0;
`

const MenuItem = styled.div`
  margin: 5px 0;
`

const MenuLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: ${props => props.theme.mainFontParagraph};
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`

const MenuExternalLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-family: ${props => props.theme.mainFontParagraph};
  transition: color 0.2s ease;

  &:hover {
    color: ${props => props.theme.secondaryColor};
  }
`
