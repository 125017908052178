import React, {createContext, useContext, useState, useEffect} from 'react';

const UTMContext = createContext(null)

function extractUTMParameter(location){
  //TODO check if this works here
  try {
    let urlParameter = location.search.substring(1);
    let obj = urlParameter.split('&').reduce((result, parameter)=>{
      if(parameter.startsWith("utm_medium") || parameter.startsWith("utm_source") || parameter.startsWith("utm_campaign")){
        let [key, value] = parameter.split('=');
        result[key] = value;
      } 
      return result;
    }, {});
    return obj
  } catch (e) {
    console.log(e)
  }
}

export const UTMContextProvider = ({children, location}) => {
  const [UTMParameter, setUTMParameter] = useState('');

  
  useEffect(()=> {
    // extract and set UTM Parameter
    const defaultParameter  = {
      utm_campaign:"",
      utm_medium:"",
      utm_source:""
    }
    const utms = extractUTMParameter(location)
    const constructedParameter = {...defaultParameter,...utms}
    setUTMParameter(constructedParameter)
  },[])

  return (
    <UTMContext.Provider value={{UTMParameter, setUTMParameter}}>
      {children}
    </UTMContext.Provider>
  );
}

export default function useUTMContext(){
  return useContext(UTMContext)
}